import React from "react"
import { TextField } from "@mui/material"

const TextBox = ({
	dom,
	sec,
	res,
	name,
	label,
	defaultText,
	error,
	errorMessage,
	fullWidth,
	isCreationMode,
	minWidth,
	onChange,
	onFocus,
	required,
	type,
	updateAble,
	valueText,
	inputProps,
	autocomplete,
	setTextInputRef,
	...props
}) => {
	if (isCreationMode === undefined) { isCreationMode = false }
	if (!autocomplete) {
		return (
			<TextField
				{...props}
				inputRef={setTextInputRef}
				dom={dom}
				sec={sec}
				name={name}
				label={label}
				inputProps={{ ...inputProps, style: { ...inputProps?.style, textAlign: inputProps?.style?.textAlign ? inputProps?.style?.textAlign : type === 'number' ? 'right' : 'inherit' } }}
				disabled={isCreationMode ? false : !updateAble}
				value={isCreationMode ? defaultText : valueText}
				InputLabelProps={{ shrink: true }}
				error={error}
				fullWidth={!!fullWidth}
				helperText={props?.helperText || errorMessage}
				minwidth={minWidth}
				autoComplete="off"
				onChange={onChange}
				onFocus={onFocus}
				required={required}
				size="small"
				type={type}
				variant="outlined"
			/>
		)
	}
	return (
		<TextField
			{...props}
			dom={dom}
			sec={sec}
			name={name}
			label={label}
			inputProps={inputProps}
			disabled={isCreationMode ? false : !updateAble}
			value={isCreationMode ? defaultText : valueText}
			InputLabelProps={{ shrink: true }}
			error={error}
			fullWidth={fullWidth}
			helperText={props?.helperText || errorMessage}
			minwidth={minWidth}
			onChange={onChange}
			onFocus={onFocus}
			required={required}
			size="small"
			type={type}
			variant="outlined"
		/>
	)
}

export default TextBox
