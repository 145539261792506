import React from "react"
import PropTypes from 'prop-types'
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

const SelectMuiCustom = ({ name, list, value, onChange, label }) => {
	return (
		<FormControl variant="outlined" fullWidth>
			<InputLabel id={`select-mui-custom-${name}-label`}>{label}</InputLabel>
			<Select
				labelId={`select-mui-custom-${name}-label`}
				id={`select-mui-custom-${name}`}
				value={value}
				label={label}
				onChange={onChange}
				name={name}
			>
				{list.map((item, i) => {
					return (
						<MenuItem key={`select-mui-custom-${name}-item-${i}`} value={item.value}>{item.name}</MenuItem>
					)
				})}
			</Select>
		</FormControl>
	)
}

SelectMuiCustom.propTypes = {
	name: PropTypes.string.isRequired,
	list: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.any.isRequired,
		name: PropTypes.string.isRequired,
	})).isRequired,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
}

export default SelectMuiCustom
