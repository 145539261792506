import React, { createContext, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLanguage } from '../redux/slices/appSlice'


export const LanguageContext = createContext({})

function LCP({ children }) {
	const dispatch = useDispatch()
	const { language } = useSelector(({ app }) => app)

	const handleToggleLanguage = (language) => {
		dispatch(toggleLanguage({ language: language }))
	}

	const initialValue = {
		language: language,
		toggleLanguage: handleToggleLanguage,
	}

	return (
		<LanguageContext.Provider value={initialValue}>
			{children}
		</LanguageContext.Provider>
	)
}
export const useLanguageContext = () => useContext(LanguageContext)

export default LCP