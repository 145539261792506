import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#EFEFEF',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'fixed',
		width: '100%',
		height: '100%',
		zIndex: 1001,
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		opacity: .5
	},
	loader: {
		position: 'fixed',
		top: '50%',
		zIndex: 1001,
		left: '50%',
		transform: 'translate(-50%, -50%)'
	}
});

export default useStyles