import { Accordion, AccordionSummary, AccordionDetails, Card, CardHeader, Typography, Grid } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react'

import { useResources } from '../../../layers/resourceLayer';

const DropDownListEntries = ({ entries = [], voucher = {}, displayedVoucherAmount = 0, displayedVoucherPercent = 0 }) => {

	const domain = "Collect";
	const section = "CollectExtranet";
	const resource = "DropDownListEntries";
	const resources = useResources(domain, section, resource)

	const [accordionState, setAccordionState] = useState(true);

	return (
		<Accordion defaultExpanded={false} expanded={!!entries.length && accordionState} onChange={_ => setAccordionState(!accordionState)}>
			{/* <AccordionSummary
				expandIcon={<ExpandMoreIcon />}
			>
				<Grid container direction="row" justifyContent="space-between" alignItems="center">
					<Grid item xs={1}>
						<Typography>{resources?.entries?.metaTitle || resources?.entries?.title || "Entries"}</Typography>
					</Grid>
					<Grid item xs={8}>
						<Typography>{resources?.voucher?.metaTitle || resources?.voucher?.title || "Allocate Voucher"}</Typography>
						<Grid
							container
							direction="row"
							justifyContent="space-between"
							alignItems="center">
							<Grid item xs>
								<span>{resources?.voucherType?.metaTitle || resources?.voucherType?.title || "Type"}: {voucher?.EVoucher || voucher?.name}</span>
							</Grid>
							<Grid item xs>
								<span>{resources?.voucherAmount?.metaTitle || resources?.voucherAmount?.title || "Amount"}: {displayedVoucherAmount}</span>
							</Grid>
							<Grid item xs>
								<span>{resources?.voucherPercent?.metaTitle || resources?.voucherPercent?.title || "Percent"}: {displayedVoucherPercent}</span>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</AccordionSummary> */}
			<AccordionDetails>
				{entries.map((entry, entryIdx) => {
					return (
						<Card sx={{ m: 0.5 }} key={`card-basket-summary-entry-${entryIdx}`}>
							<CardHeader
								title={`${entry?.metaTitle || entry?.title || entry?.name} - qte: ${entry?.qte || 1}`}
							// subheader={`${entry?.itemName} - ${entry.itemDescription}`}
							/>
						</Card>
					)
				})}
			</AccordionDetails>
		</Accordion >
	)
}

export default DropDownListEntries