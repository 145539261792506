import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import LanguageContextProvider from './contexts/language.context'
import RecycleThemeProvider from "./themes/RecycleThemeProvider";

import LoginPage from "./pages/Login";
import NotFound from "./pages/NotFound";
import CollectsPage from "./pages/Collects";
import ScanPage from "./pages/Scan";


function App() {
	const { isConnected } = useSelector(({ auth }) => auth)
	return (
		<Router>
			<LanguageContextProvider>
				<RecycleThemeProvider>
					<Routes>
						{(isConnected === true)
							?
							<>
								<Route path="/" element={<CollectsPage />} />
								<Route path="/scan" element={<ScanPage />} /> 
								<Route path="/*" element={<NotFound />} />
							</>
							:
							<Route path="*" element={<LoginPage />} />
						}
					</Routes>
				</RecycleThemeProvider>
			</LanguageContextProvider>
		</Router>
	)
}

export default App

