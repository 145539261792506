import { createSlice } from '@reduxjs/toolkit'

const resXSlice = createSlice({
	name: 'resX',
	initialState: {
	},
	reducers: {
		insertResource(state, action) {
			const language = action.payload.language
			const domain = action.payload.domain
			const section = action.payload.section
			const resource = action.payload.resource
			const selectResource = action.payload.selectResource
			return {
				...state,
				[language]: {
					...state?.[language],
					[domain]: {
						...state?.[language]?.[domain],
						[section]: {
							...state?.[language]?.[domain]?.[section],
							[resource]: selectResource
						}
					}
				}
			}
		},
		resetResources(state, action) {
			return {}
		}
	}
})

export const { insertResource } = resXSlice.actions

export default resXSlice.reducer