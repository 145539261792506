import React from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useResources } from '../../../layers/resourceLayer';
import { Grid, IconButton } from '@mui/material';

const VouchersSummary = ({ vouchers = [] }) => {
	const domain = "Collect";
	const section = "CollectExtranet";
	const resource = "VouchersSummary";
	const resources = useResources(domain, section, resource)

	const handleCopy = (code) => {
		navigator.clipboard.writeText(code)
	}

	if (!vouchers || !vouchers.length) {
		return null
	}

	return (vouchers.filter((it) => (it.amountDebit > 0 || it.percentDebit > 0)).map((voucher, vidx) => {
		return (
			<div key={`voucher-summary-idx-${vidx}`} style={{ padding: "10px 0", width: "100%", boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" }} >
				<div style={{ position: "relative", width: "80%", margin: "10px auto", fontSize: "1.5em" }}>
					<div style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold", letterSpacing: ".2rem", border: "solid 1px black", borderRadius: "5px", borderBottomRightRadius: "0", padding: "10px" }}>
						<Grid container spacing={1} columns={12} direction="row" justifyContent="space-between" alignItems="stretch">
							<Grid item xs>
								{voucher.voucherCode}
							</Grid>
							<Grid item xs={2}>
								<IconButton onClick={_ => handleCopy(voucher.voucherCode)}>
									<ContentCopyIcon />
								</IconButton>
							</Grid>
						</Grid>
					</div>
					<span style={{ marginLeft: "auto", textAlign: "right", display: "block", width: "fit-content", border: "solid 1px black", borderTop: "0", padding: "6px", borderBottomRightRadius: "5px", borderBottomLeftRadius: "5px" }}>
						{
							(voucher.amountDebit > 0)
								?
								`${voucher.amountDebit}€`
								:
								`${voucher.percentDebit}%`
						}
					</span>
				</div>
			</div>
		)
	}))
}

export default VouchersSummary