import axios from 'axios';

const baseURL = process.env.REACT_APP_EXTRANET_API + "/api/recycle/extranet/v1"

const axiosInstance = new axios.create({
	baseURL: baseURL,
	timeout: 20000,
	withCredentials: false,
	headers: {
		"Content-Type": "application/json",
		'Accept': 'application/json'
	},
});

axiosInstance.interceptors.request.use(function (config) {
	if (!config.headers.Authorization) {
		config.headers.Authorization = "Bearer " + localStorage.getItem(`auth.accessToken`);
		return config;
	} else { return config }
}, null, { synchronous: true });

export const GetUserCollects = async () => {
	const uri = `/collects`
	try {
		const response = await axiosInstance.get(uri)
		return await response.data
	} catch (err) {
		console.error('[GetUserCollects]->catch : ', ' => ', uri, ' => ', err)
		throw err
	}
}

export const GetDemandInfo = async (demandIdentifier, language) => {
	const uri = `/demand/${demandIdentifier}/${language}`
	try {
		const response = await axiosInstance.get(uri)
		return await response.data
	} catch (err) {
		console.error('[GetDemandInfo]->catch : ', ' => ', uri, ' => ', err)
		throw err
	}
}

export const ConfirmDemand = async (demandId, language) => {
	const uri = `/demand/confirm`
	try {
		const response = await axiosInstance.post(uri, { demandId: demandId, language: language })
		return await response.data
	} catch (err) {
		console.error('[PostConfirmDemand]->catch : ', ' => ', uri, ' => ', err)
		throw err
	}
}

export const ResendEmail = async (demandId) => {
	const uri = `/demand/email/resend`
	try {
		const response = await axiosInstance.post(uri, { demandId: demandId })
		return await response.data
	} catch (err) {
		console.error('[PostResendEmail]->catch : ', ' => ', uri, ' => ', err)
		throw err
	}
}