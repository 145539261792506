import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import { Button, CircularProgress, IconButton, InputAdornment, Paper, Grid, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'

import TextBox from "../../common/TextBox"
import SelectMuiCustom from '../../common/SelectMuiCustom'
import { useResources } from '../../../layers/resourceLayer';
import { useLanguageContext } from '../../../contexts/language.context';

function LoginComponent({ onSubmitAction }) {
	const isCreationMode = true
	const domain = "Global";
	const section = "Auth";
	const resource = "Login";

	const resources = useResources(domain, section, resource)
	const { language, toggleLanguage } = useLanguageContext()

	const [showPassword, setShowPassword] = useState(false)

	const [state, setState] = useState({
		login: "",
		password: "",
		remember: false
	})

	const { error, loading } = useSelector(({ auth }) => auth)

	function handleChange(e) {
		const newValue = ((typeof state[e.target.name] === 'boolean')) ? e.target.checked : e.target.value
		setState({
			...state,
			[e.target.name]: newValue
		})
	}

	const handleOnSubmitInSelf = () => {
		onSubmitAction({ ...state })
	}

	if (!resources) {
		return (
			<CircularProgress />
		)
	}

	return (
		<Grid item xs={12} container justifyContent={"center"} >
			<form onSubmit={e => { e.preventDefault(); handleOnSubmitInSelf() }}>
				<Paper elevation={0} component="h2" >
					{resources?.signIn?.metaTitle || resources?.signIn?.title}
				</Paper>
				{(error && error.message && error.message.length) ? <Grid item><Typography color="error" align="center" display="block" variant="subtitle1" component="span">{error.message}</Typography></Grid> : null}
				<Grid item style={{ paddingBottom: "0.5rem" }}>
					<TextBox
						dom={domain}
						sec={section}
						res={resource}
						name="login"
						label={resources?.login?.metaTitle || resources?.login?.title}
						isCreationMode={isCreationMode}
						updateAble={false}
						onChange={handleChange}
						autoFocus
						required
						fullWidth
					/>
				</Grid>
				<Grid item style={{ paddingBottom: "0.5rem" }}>
					<TextBox
						dom={domain}
						sec={section}
						res={resource}
						name="password"
						label={resources?.password?.metaTitle || resources?.password?.title}
						type={showPassword ? 'text' : 'password'}
						isCreationMode={isCreationMode}
						updateAble={false}
						onChange={handleChange}
						required
						fullWidth
						minWidth="400px"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => setShowPassword(!showPassword)}>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</Grid>

				<Grid item style={{ paddingBottom: "0.5rem" }}>
					<SelectMuiCustom
						name={"languageSelected"}
						list={[{ name: "Français", value: 'Fr' }, { name: "English", value: 'En' }, { name: "Española", value: 'Es' }]}
						value={language}
						onChange={e => toggleLanguage(e.target.value)}
						variant
						label={resources?.language?.metaTitle || resources?.language?.title}
					/>
				</Grid>

				<Grid item>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						disabled={loading}
					>
						{loading === true ? <CircularProgress /> : resources?.submitBtn?.metaTitle || resources?.submitBtn?.title}
					</Button>
				</Grid>
			</form>
		</Grid>
	)
}

LoginComponent.propTypes = {
	onSubmitAction: PropTypes.func.isRequired
}

export default LoginComponent