import React from 'react'
import MainLayoutView from '../../layouts/MainLayoutView'
import ScanView from '../../views/Scan'

const ScanPage = () => {
	return (
		<MainLayoutView>
			<ScanView />
		</MainLayoutView>
	)
}

export default ScanPage