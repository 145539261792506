import axios from 'axios';

const baseURL = process.env.REACT_APP_STATIC_API + "/api/recycle/static/v1"

const axiosInstance = new axios.create({
	baseURL: baseURL,
	timeout: 20000,
	withCredentials: false,
	headers: {
		"Content-Type": "application/json",
		'Accept': 'application/json'
	},
});

export const ReadResourceByDomainSectionLanguage = async (domain, section, resourceName, language) => {
	const uri = `/resource/${domain}/${section}/${resourceName}/${language}`
	try {
		axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
		const response = await axiosInstance.get(uri);
		return await response.data;
	} catch (err) {
		console.error('[ReadResourceByDomainSectionLanguage]->catch : ', ' => ', uri, ' => ', err)
	}
}
