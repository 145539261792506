import React from 'react'

import LoginComponent from '../../components/AuthManager/Login'

function SignInView({ onSubmitAction }) {
	return (
		<LoginComponent onSubmitAction={onSubmitAction} />
	)
}

export default SignInView