import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import MainLayoutView from '../../layouts/MainLayoutView'
import SignInView from '../../views/SignIn'
import { handleResponse } from '../../clientApi/recycle/proxy'
import { LoginAction } from '../../clientApi/recycle/auth'
import { setUserProfile, signInError, signInRequest, signInSuccess } from '../../redux/slices/authSlice'

const LoginPage = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate();

	const handleSignIn = async ({ login, password }) => {
		dispatch(signInRequest())
		const result = await dispatch(handleResponse(LoginAction, login, password))
		if (result.success === true) {
			dispatch(signInSuccess({ ...result.data }))
			// await dispatch(signInSuccess({ ...result.data }))
			// const profileResponse = await dispatch(handleResponse(GetCurrentUser))
			// dispatch(setUserProfile({ profile: profileResponse.data }))
			navigate('/')
		} else {
			dispatch(signInError({
				message: result.error,
				type: '401'
			}))
		}
	}

	return (
		<MainLayoutView>
			<SignInView onSubmitAction={handleSignIn} />
		</MainLayoutView>
	)
}

export default LoginPage