import { Grid } from '@mui/material'
import React from 'react'
import SignOutBtn from '../SignOutBtn'

import './style.scss'

const HeaderMenu = () => {
	return (
		<div className='header-container'>
			<div className='one'>
				<img className="logo" alt="logo-recycle" src="/images/recycle_vert_blanc.png" />
			</div>
			<div></div>
			<div className='three'>
				<SignOutBtn />
			</div>
		</div>
	)
}

export default HeaderMenu 