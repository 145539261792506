import { Divider, Button, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, LinearProgress, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react'
import { useResources } from '../../../layers/resourceLayer';
import TextBox from '../../common/TextBox';
import DropDownListEntries from '../DropDownListEntries';
import VouchersSummary from '../VouchersSummary';
import { useNavigate } from 'react-router-dom';
import { ConfirmDemand, GetDemandInfo, ResendEmail } from '../../../clientApi/recycle/extranetCollect';
import { useLanguageContext } from '../../../contexts/language.context';
import { useDispatch } from 'react-redux';
import { handleResponse } from '../../../clientApi/recycle/proxy';

const initState = {
	id: '',
	loader: false,
	optin: false,
	"firstName": "",
	"lastName": "",
	"zipCode": "",
	"cityName": "",
	"mailName": "",
	"mailDom": "",
	"voucherType": {
		"id": 0,
		"key": "",
		"name": "",
		"metaTitle": "",
		"metaUrl": "",
		"enabled": false,
		"metaText": ""
	},
	"displayedVoucherAmount": 0,
	"displayedVoucherPercent": 0,
	"isLabelReceived": false,
	"createdAt": "",
	"entries": [],
	label: {
		returnSlipBarcode: ""
	},
	balanceList: [],
	balanceListSrcIsInit: true,
}

const ScanForm = () => {
	const domain = "Collect";
	const section = "CollectExtranet";
	const resource = "ScanForm";
	const resources = useResources(domain, section, resource)

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { language } = useLanguageContext()

	const [state, setState] = useState(initState)

	const handleSubmitForm = async (e) => {
		e.preventDefault();
		setState({
			...state,
			loader: true,
			error: false,
			balanceListSrcIsInit: true,
		})
		GetDemandInfo(state.id, language)
			.then((data) => {
				setState({
					...state,
					error: false,
					loader: false,
					...data,
					balanceListSrcIsInit: (data && data.balanceList && data.balanceList.length) ? true : false
				})
			})
			.catch((err) => {
				setState({
					...initState,
					id: state.id,
					loader: false,
					error: true
				})
			})
	}

	const handleConfirmAllocation = async () => {
		const result = await dispatch(handleResponse(ConfirmDemand, state.id, language))
		setState({
			...state,
			...result.data,
			balanceListSrcIsInit: false
		})
	}

	const handleResend = async () => {
		dispatch(handleResponse(ResendEmail, state.id))
	}

	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}
	const handleChangeCheckBox = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.checked
		})
	}

	const handleBack = () => {
		navigate('/')
	}

	const handleNext = () => {
		setState(initState)
	}

	return (
		<>
			{state && !!state.error && <Typography align="center" style={{ color: "red" }}>{resources?.fetchError?.metaTitle || resources?.fetchError?.title || "Cannot fetch this data."}</Typography>}
			<Grid container columns={12} direction="column" justifyContent={"center"} alignItems="stretch" spacing={3}>
				<Grid item xs={6} >
					<form onSubmit={handleSubmitForm}>
						<TextField
							name="id"
							label={resources?.demandId?.metaTitle || resources?.demandId?.title || "demandId"}
							onChange={handleChange}
							autoFocus
							required
							fullWidth
							value={state.id}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={handleSubmitForm}>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					</form>
				</Grid>
				{!!state.loader && <Grid item xs={6}><LinearProgress /></Grid>}
				<Grid item xs={6} container direction="row" justifyContent="space-evenly" alignItems="stretch" spacing={1}>
					<Grid item xs={6}>
						<TextBox
							dom={domain}
							sec={section}
							res={resource}
							name="firstName"
							label={resources?.firstName?.metaTitle || resources?.firstName?.title || "firstName"}
							isCreationMode={false}
							updateAble={false}
							onChange={handleChange}
							fullWidth
							valueText={state.firstName}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextBox
							dom={domain}
							sec={section}
							res={resource}
							name="lastName"
							label={resources?.lastName?.metaTitle || resources?.lastName?.title || "lastName"}
							isCreationMode={false}
							updateAble={false}
							onChange={handleChange}
							fullWidth
							valueText={state.lastName}
						/>
					</Grid>
				</Grid>
				<Grid item xs={6} >
					<TextBox
						dom={domain}
						sec={section}
						res={resource}
						name="email"
						label={resources?.email?.metaTitle || resources?.email?.title || "email"}
						isCreationMode={false}
						updateAble={false}
						onChange={handleChange}
						fullWidth
						valueText={(state.mailName.length && state.mailDom.length) ? `${state.mailName}@${state.mailDom}` : ""}
					/>
				</Grid>
				<Grid item xs={6} >
					<TextBox
						dom={domain}
						sec={section}
						res={resource}
						name="barCode"
						label={resources?.barCode?.metaTitle || resources?.barCode?.title || "barCode"}
						isCreationMode={false}
						updateAble={false}
						onChange={handleChange}
						fullWidth
						valueText={state?.label?.returnSlipBarcode}
					/>
				</Grid>
				<Grid item xs={6}>
					<DropDownListEntries entries={state?.entries} voucher={state?.voucherType} displayedVoucherAmount={state?.displayedVoucherAmount} displayedVoucherPercent={state?.displayedVoucherPercent} />
				</Grid>
				{(state && state.balanceList && state.balanceList.length && state.balanceList.filter((it) => (it.amountDebit > 0 || it.percentDebit > 0)).length)
					?
					null
					:
					<Grid item container xs={6} direction="row" justifyContent="space-between" alignItems="stretch" spacing={1}>
						<Grid item xs>
							<FormGroup>
								<FormControlLabel control={<Checkbox checked={state.optin} onChange={handleChangeCheckBox} name="optin" />} label={resources?.optin?.metaTitle || resources?.optin?.title || "Accepter d'accorder le bon"} />
							</FormGroup>
						</Grid>
						<Grid item xs={3}>
							<Button fullWidth variant="outlined" disabled={!state.optin} onClick={handleConfirmAllocation}>{resources?.confirmBtn?.metaTitle || resources?.confirmBtn?.title || "Confirm"}</Button>
						</Grid>
					</Grid>
				}
				{(state && state.balanceList && state.balanceList.length && state.balanceList.filter((it) => (it.amountDebit > 0 || it.percentDebit > 0)).length)
					?
					<>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={6}>
							<VouchersSummary vouchers={state?.balanceList} />
						</Grid>
						<Grid item container xs={6} direction="row" justifyContent="space-between" alignItems="stretch" spacing={1}>
							<Grid item xs>
								{
									(state.balanceListSrcIsInit === true)
										?
										<Chip label={resources?.alreadyScanned?.metaTitle || resources?.alreadyScanned?.title || "Already registered"} color="error" variant="contained" />
										:
										null
								}
							</Grid>
							<Grid item xs={4}>
								<Button onClick={handleResend} fullWidth variant='outlined'>{resources?.resendBtn?.metaTitle || resources?.resendBtn?.title || "Resend"}</Button>
							</Grid>
						</Grid>
					</>
					: null
				}
				<Grid item container xs={6} direction="row" justifyContent="space-between" alignItems="stretch" spacing={1}>
					<Grid item xs={4}>
						<Button fullWidth onClick={handleBack} variant="outlined">{resources?.back?.metaTitle || resources?.back?.title || "back"}</Button>
					</Grid>
					<Grid item xs={4}>
						<Button fullWidth color="success" onClick={handleNext} variant="outlined">{resources?.next?.metaTitle || resources?.next?.title || "next"}</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default ScanForm