import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LogoutIcon from '@mui/icons-material/Logout';

import { useResources } from '../../../layers/resourceLayer';
import { logout } from '../../../redux/slices/authSlice'
import { useNavigate } from 'react-router-dom';

const CustomBtn = styled(Button)(({ theme }) => ({
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textAlign: "left",
	display: "inline-block",
	startIcon: {
		margin: 0
	}
}));

const SignOutBtn = () => {

	const domain = "Global";
	const section = "Global";
	const resource = "MenuSettings";
	const resources = useResources(domain, section, resource)
	const navigate = useNavigate()

	const dispatch = useDispatch()
	const { profile: { name }, isConnected } = useSelector(({ auth }) => auth)
	const handleLogout = () => {
		navigate('/')
		dispatch(logout())
	}
	if (!isConnected) {
		return null
	}
	return (
		<div style={{ width: "100%" }}>
			<CustomBtn fullWidth color="error" startIcon={<LogoutIcon sx={{ mb: "-4px", display: "inline" }} />} variant="contained" onClick={handleLogout}>
				{resources?.logout}
			</CustomBtn>
		</div>
	)
}

export default SignOutBtn