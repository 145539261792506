import React from 'react'
import PropTypes from 'prop-types'

import * as Themes from './index'

const RecycleThemeProvider = ({ theme, children }) => {
	const SelectedTheme = Themes[theme]
	return (
		<SelectedTheme>
			{children}
		</SelectedTheme>
	)
}

RecycleThemeProvider.propTypes = {
	theme: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
}

RecycleThemeProvider.defaultProps = {
	theme: 'RecycleMaterial'
}

export default RecycleThemeProvider
