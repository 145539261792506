import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
	name: 'app',
	initialState: {
		language: 'Fr',
		overlay: false,
		overlay_error: false,
		overlay_loader: false,
	},
	reducers: {
		toggleLanguage(state, action) {
			return {
				...state,
				language: action.payload.language
			}
		},
		setOverlay(state, action) {
			return {
				...state,
				overlay: action.payload.overlay,
				overlay_loader: action.payload.overlay_loader,
				overlay_error: action.payload.overlay_error
			}
		}
	},
})

export const { toggleLanguage, setOverlay } = appSlice.actions

export default appSlice.reducer