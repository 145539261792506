import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GetUserCollects } from '../../clientApi/recycle/extranetCollect'
import { handleResponse } from '../../clientApi/recycle/proxy'
import ListCollects from '../../components/CollectManager/ListCollects'

const CollectsView = () => {
	const dispatch = useDispatch()
	const [state, setState] = useState({
		list: [],
		loader: true,
		error: false,
		errorDetails: null
	})
	useEffect(() => {
		if (state.loader) {
			dispatch(handleResponse(GetUserCollects))
				.then((result) => {
					if (!!result.success) {
						setState({
							...state,
							list: result?.data || [],
							loader: false,
							error: false,
							errorDetails: null
						})
					} else {
						setState({
							...state,
							list: [],
							loader: false,
							error: true,
							errorDetails: result.error
						})
					}
				})
		}
	})

	if (!!state.loader) {
		return <CircularProgress />
	}
	if (!!state.error) {
		return <p>{state.errorDetails}</p>
	}
	if (!state?.list) {
		return null
	}
	return (
		<ListCollects list={state?.list} />
	)
}

export default CollectsView