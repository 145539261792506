import axios from 'axios'
import md5 from 'md5'
import { Buffer } from 'buffer';

const baseURL = process.env.REACT_APP_AUTH_API + "/api/recycle/auth/v1"

const axiosInstance = new axios.create({
    baseURL: baseURL,
    timeout: 20000,
    withCredentials: false,
    headers: {
        "Content-Type": "application/json",
        'Accept': "application/json"
    },
});

axiosInstance.interceptors.request.use(function (config) {
    if (!config.headers.Authorization) {
        config.headers.Authorization = "Bearer " + localStorage.getItem(`auth.accessToken`);
        return config;
    } else { return config }
}, null, { synchronous: true });

export const LoginAction = async (login, password) => {
    try {
        const _login = login.replace(/:$/, "");
        const md5pass = md5(password);
        const encodedString = Buffer.from(_login + ":" + md5pass).toString('base64');
        const response = await axiosInstance.get(`/auth-external`, { headers: { Authorization: "Basic " + encodedString } })
        return response?.data
    } catch (err) {
        console.error('[GetLogin]->catch : ', err)
        throw new Error("Your login or password is invalid.")
    }
}

export const GetCurrentUser = async () => {
    try {
        const result = await axiosInstance.get(`/user`)
        return result.data
    } catch (err) {
        console.error('[GetCurrentUser]->catch : ', err)
        throw new Error("Cannot fetch this profile")
    }
}