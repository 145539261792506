import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	profile: {},
	isConnected: false,
	loading: false,
	accessToken: '',
	refreshToken: '',
	error: {
		message: '',
		type: ''
	}
}

const authSlice = createSlice({
	name: 'auth',
	initialState: initialState,
	reducers: {
		signInRequest(state) {
			return {
				...state,
				...initialState,
				loading: true,
			}
		},
		signInSuccess(state, action) {
			const { accessToken, refreshToken, ...profile } = action.payload
			return {
				...state,
				isConnected: true,
				loading: false,
				accessToken: accessToken,
				refreshToken: refreshToken,
				profile: { ...profile }
			}
		},
		signInError(state, action) {
			return {
				...state,
				...initialState,
				error: {
					message: action.payload.message,
					type: action.payload.type
				}
			}
		},
		logout() {
			return initialState
		},
		setUserProfile(state, action) {
			return {
				...state,
				profile: action.payload.profile
			}
		},
	}
})

export const {
	signInRequest,
	signInSuccess,
	signInError,
	logout,
	setUserProfile,
} = authSlice.actions

export default authSlice.reducer