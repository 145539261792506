import React from 'react'
import MainLayoutView from '../../layouts/MainLayoutView'
import CollectsView from '../../views/Collects'

const CollectsPage = () => {
	return (
		<MainLayoutView>
			<CollectsView />
		</MainLayoutView>
	)
}

export default CollectsPage