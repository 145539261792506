import { Avatar, Button, Card, CardActions, CardHeader, Grid } from '@mui/material'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

import React from 'react'
import { useResources } from '../../../layers/resourceLayer';
import { useNavigate } from 'react-router-dom';

const ListCollects = ({ list }) => {
	const domain = "Collect";
	const section = "CollectExtranet";
	const resource = "ListCollects";
	const resources = useResources(domain, section, resource)
	const navigate = useNavigate()

	const handleScan = () => {
		navigate('/scan')
	}

	return (
		<Grid
			container
			direction="column"
			justifyContent="space-evenly"
			alignItems="center"
			spacing={4}
		>
			{list.map((item, itx) => {
				return (
					<Grid key={`collect-item-${itx}`} item xs={4}>
						<Card sx={{ maxWidth: 560, minWidth: 400 }}>
							<CardHeader
								avatar={
									<Avatar>
										{item.deal.metaTitle.charAt(0).toUpperCase()}
									</Avatar>
								}
								title={item.deal.metaTitle}
								subheader={`status: ${item.status.metaTitle}`}
							/>
							<CardActions>
								<Grid
									container
									direction="row"
									justifyContent="flex-end"
									alignItems="stretch"
									spacing={1}
								>
									<Grid item>
										<Button disabled variant="outlined" startIcon={<FormatListNumberedIcon />}>{resources?.list?.metaTitle || resources?.list?.title}</Button>
									</Grid>
									<Grid item>
										<Button onClick={handleScan} variant="outlined" startIcon={<QrCodeScannerIcon />}>{resources?.scan?.metaTitle || resources?.scan?.title}</Button>
									</Grid>
								</Grid>
							</CardActions>
						</Card>
					</Grid>
				)
			})}
		</Grid>
	)
}

export default ListCollects