import { Grid } from '@mui/material'
import React from 'react'
import HeaderMenu from '../../components/common/HearderMenu'

const MainLayoutView = ({ children }) => {
	return (
		<Grid container spacing={4} direction="column" justifyContent="flex-start" alignItems="stretch">
			<Grid item xs={12}>
				<HeaderMenu />
			</Grid>
			<Grid item xs={12}>
				{children}
			</Grid>
		</Grid>
	)
}

export default MainLayoutView