import { Grid } from '@mui/material'
import React from 'react'
import ScanForm from '../../components/CollectManager/ScanForm'

const ScanView = () => {
	return (
		<Grid container justifyContent={"center"} spacing={1}>
			<Grid item xs={10} md={4}>
				<ScanForm />
			</Grid>
		</Grid>
	)
}

export default ScanView