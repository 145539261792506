import React, { useEffect, useState } from 'react'
import { CircularProgress, Dialog, DialogTitle } from "@mui/material";
import useStyles from "./style";


const Overlay = ({ active, isError, isLoading }) => {

	const classes = useStyles()
	const error = active && isError
	const loading = active && isLoading
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (error && active) setOpen(true)
	}, [error, active]);

	return (
		<div className={loading ? classes.root : ''}>
			{loading && <CircularProgress className={classes.loader} />}
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
			>
				<DialogTitle>Error Loading: Please Retry </DialogTitle>
			</Dialog>
		</div>)
}

export default Overlay


