import { setOverlay } from "../../redux/slices/appSlice";

export const handleResponse = (method, ...params) => async (dispatch) => {
	let response;
	dispatch(setOverlay({
		overlay: true,
		overlay_loader: true,
		overlay_error: false
	}))
	try {
		const apiResponse = await method(...params)
		if (!apiResponse) {
			setTimeout(dispatch(setOverlay({
				overlay: false,
				overlay_loader: false,
				overlay_error: false
			})), 25000)

		} else {
			dispatch(setOverlay({
				overlay: false,
				overlay_loader: false,
				overlay_error: false
			}))
		}
		response = {
			success: true,
			data: apiResponse
		}
	} catch (err) {
		response = {
			success: false,
			data: [],
			error: err.toString()
		}
		dispatch(setOverlay({
			overlay: false,
			overlay_loader: false,
			overlay_error: false
		}))
	}
	return response
}