import { configureStore } from '@reduxjs/toolkit'
import { throttle } from 'lodash'

import { loadState, saveState } from './localstorage'

import appReducer from './slices/appSlice'
import authSlice from './slices/authSlice'
import resXReducerSlice from './slices/resXReducerSlice'

const authMiddleware = (store) => (next) => (action) => {
	if (action.type === 'auth/signInSuccess') {
		localStorage.setItem('auth.accessToken', action?.payload?.accessToken);
	} else if (action.type === 'auth/logout') {
		localStorage.removeItem('auth.accessToken');
	}
	return next(action);
};


const mainStore = configureStore({
	reducer: {
		app: appReducer,
		resX: resXReducerSlice,
		auth: authSlice,

	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware),
	preloadedState: loadState(),
	devTools: (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "staging") ? true : false
})

mainStore.subscribe(throttle(() => {
	saveState({
		...mainStore.getState()
	})
}))

export default mainStore