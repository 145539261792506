import React from 'react'
import PropTypes from 'prop-types'

import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/styles';
import { useSelector } from "react-redux";
import Overlay from "../components/common/Overlay";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#11a103",
		},
		secondary: {
			main: "#9303A0"
		},
		error: {
			main: '#C2062A',
		},
	},
});

function RecycleMaterialTheme({ children }) {

	const { overlay, overlay_error, overlay_loader } = useSelector(state => state.app);

	return (
		<ThemeProvider theme={theme}>
			<Overlay
				active={overlay}
				isError={overlay_error}
				isLoading={overlay_loader}
			/>
			{children}
			<div id="modal" />
		</ThemeProvider>
	)
}

RecycleMaterialTheme.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
}

export default RecycleMaterialTheme