import Axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { merge } from 'lodash';

import { useLanguageContext } from '../contexts/language.context';
import { insertResource } from '../redux/slices/resXReducerSlice';
import { ReadResourceByDomainSectionLanguage } from '../clientApi/recycle/resx';

export const useResources = (domain, section, resource) => {

	const { language } = useLanguageContext();

	const selectResource = useSelector(({ resX }) => {
		return resX[language]?.[domain]?.[section]?.[resource];
	});
	const dispatch = useDispatch();
	const devMode = false;

	useEffect(() => {
		if (!selectResource) {
			loadResourceFromOrigin();
		}
	}, [language, domain, section, resource, selectResource]);

	const loadResourceFromOrigin = async () => {
		const _uri = `/resources/${domain}/${section}/${resource}.json`;
		Axios.get(_uri).then(async (metaJsonFromUri) => {
			if (!metaJsonFromUri) {
			} else {
				let resourceRead = {};
				if (!devMode) {
					resourceRead = await ReadResourceByDomainSectionLanguage(domain,
						section,
						resource, language);
				}

				const combineJson = merge(await metaJsonFromUri.data, resourceRead);
				dispatch(insertResource({
					language: language,
					domain: domain,
					section: section,
					resource: resource,
					selectResource: combineJson,
				}))
			}
		});

	};

	return selectResource;
};